import { Injectable } from '@angular/core';
import { Product } from '../shared/types/xhr.types';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
declare var dataLayer: Array<any>

@Injectable({
  providedIn: 'root'
})
export class EventTrackingService {

  constructor(
    private commonService: CommonService,
  ) { }

  // gtag('event'

  googleTagEvent(event_name: string, event_data?: any) {
    if (environment.production && this.commonService.isBrowser && typeof dataLayer === 'object') {
      console.log('event', event_name, event_data)
      dataLayer?.push({ ecommerce: null })
      dataLayer?.push({ event: event_name, ecommerce: event_data })
    }
  }

  viewProduct(product: Product) {
    try {
      const eventData = {
        user: this.commonService.getUser()?.user?._id || null,
        productId: product._id,
        name: product.name,
        categoryId: product.primaryCategory?._id || null,
        categoryName: product.primaryCategory?.name || null,
        brandId: product.brandId?._id || null,
        brandName: product.brandId?.name || null,
        availability: product.stock?.availableQuantity ? "In Stock" : "Out of Stock",
        description: product.description?.short?.replace(/<[^>]*>?/gm, '') || null,
        rating: product.averageRating,
        images: product.images?.map(el => environment.imageUrl + el.savedName) || null,
        features: product.attributes || null,
      };
      this.googleTagEvent('product_detail_views', eventData)
    } catch (e) {
      console.error('[event tracking]', e)
    }
  }

  addToCart(product: { _id: string, name: string, quantity: number }) {
    try {
      const eventData = {
        user: this.commonService.getUser()?.user?._id || null,
        productId: product._id,
        name: product.name,
        quantity: product.quantity,
      };

      this.googleTagEvent('add_to_cart', eventData)
    } catch (e) {
      console.error('[event tracking]', e)
    }
  }

  removeFromCart(product: { _id: string, name: string }) {
    try {
      const eventData = {
        user: this.commonService.getUser()?.user?._id || null,
        productId: product._id,
        name: product.name,
      };
      this.googleTagEvent('remove_from_cart', eventData)
    } catch (e) {
      console.error('[event tracking]', e)
    }
  }

  beginCheckout() {
    try {
      let user = this.commonService.getUser()?.user?._id || null;
      this.googleTagEvent('begin_checkout', { user })
    } catch (e) {
      console.error('[event tracking]', e)
    }
  }

  purchaseCompleted(data: any) {
    try {
      this.googleTagEvent('purchase', data)
    } catch (e) {
      console.error('[event tracking]', e)
    }
  }

  viewCart() {
    try {
      let user = this.commonService.getUser()?.user?._id || null;
      this.googleTagEvent('view_cart', { user })
    } catch (e) {
      console.error('[event tracking]', e)
    }
  }



}
